<template>
  <el-dialog
    title="设置角色权限"
    :visible="visible"
    width="70%"
    :show-close="false"
    v-loading="loading"
  >
    <section class="scroll-content">
      <el-form :model="form" ref="MenuTree" label-width="100px" size="small">
        <el-form-item prop="roleId" v-show="false">
          <el-input disable v-model="form.roleId"></el-input>
        </el-form-item>
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="form.roleName" disabled />
        </el-form-item>
        <el-form-item label="角色描述：" prop="description">
          <el-input
            v-model="form.description"
            type="textarea"
            rows="1"
            disabled
          />
        </el-form-item>
        <el-form-item label="权限清单：">
          <vxe-grid
            :ref="tableRef"
            :rowId="rowId"
            height="300"
            border
            :data="dataSource"
            :columns="tableColumn"
            size="small"
            v-bind="tableOptions"
            @checkbox-change="handleCheckboxChange"
            @checkbox-all="handleCheckboxAll"
          >
            <template #nodePort="{ row }">
              <span v-if="row.nodePort === 1">运营端</span>
              <span v-if="row.nodePort === 2">企微端</span>
              <span v-if="row.nodePort === 3">供应端</span>
              <span v-if="row.nodePort === 4">企业端</span>
            </template>
            <template #nodeType="{ row }">
              <span v-if="row.nodeType === 1">菜单</span>
              <span v-if="row.nodeType === 2">操作</span>
            </template>

            <template #isNeedDataPermission="{ row, $rowIndex }">
              <el-checkbox
                :checked="row.dataPermission.includes(1)"
                v-if="row.isNeedDataPermission !== 0"
                label="1"
                @change="e => handlePowerChange(e, row, $rowIndex, 1, 0)"
              >仅部门权限</el-checkbox>
            </template>
          </vxe-grid>
        </el-form-item>
      </el-form>
    </section>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" :loading="conformLoading" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getUserPowerByRoleId, saveRolePermission } from '@/api/role';
import { unionBy, remove } from "lodash"

export function listToTree (list) {
  list.forEach((item, index) => {
    const { nodeId } = item;
    const children = list.filter(item => item.parentId === nodeId)
    item.children = children;
  });
  return list
}

export default {
  name: 'MenuTreeModal',
  data () {
    return {
      rowId: 'nodeId',
      tableRef: 'MenuTable',
      visible: false,
      loading: false,
      conformLoading: false,
      treeData: [],
      tableColumn: [
        { type: 'checkbox', fixed: 'left', with: 180, treeNode: true },
        { field: 'nodeType', title: '节点类型', slots: { default: 'nodeType' } },
        { field: 'nodePort', title: '菜单显示端', slots: { default: 'nodePort' } },
        { field: 'isNeedDataPermission', title: '数据权限', slots: { default: 'isNeedDataPermission' } },
      ],
      dataSource: [],
      // nodeIds: [],
      roleNodes: [],// 授权节点 { nodeId , isNeedDataPermission, dataPermission  }
      form: {
        roleId: '',
        roleName: '',
        description: ''
      },
      tableOptions: {
        expandConfig: {
          expandAll: false
        },
        treeConfig: {
          transform: true,
          rowField: 'nodeId',
          parentField: 'parentId',
          expandAll: true
        },
        checkboxConfig: {
          labelField: 'nodeName',
          checkRowKeys: [],
          reserve: false // 保留勾选状态
        }
      }
    };
  },
  computed: {
    roleId () {
      return this.form.roleId;
    }
  },
  watch: {

    roleId (newVal) {
      if (newVal) {
        this.getActiveMenus();
      }
    }
  },
  methods: {

    handleCancel () {
      this.visible = false;
      this.form.roleId = '';
      this.treeData = [];
      this.dataSource = [];
      // this.nodeIds = [];
      this.roleNodes = [];
      this.form.roleName = '';
      this.form.description = '';
    },
    async handleOk () {
      if (this.conformLoading) {
        return;
      }
      this.conformLoading = true;
      const roleNodes = [];
      this.roleNodes.forEach(node => {
        roleNodes.push({
          nodeId: node.nodeId,
          isNeedDataPermission: node.isNeedDataPermission,
          dataPermission: node.dataPermission
        })
      })
      try {
        const params = { data: { roleId: this.form.roleId, roleNodes, } };
        const res = await saveRolePermission(params);
        this.conformLoading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message.success('操作成功');
        this.handleCancel();
        this.$emit('setPermissionOK');
      } catch (e) {
        this.conformLoading = false;
      }
    },
    async getActiveMenus () {
      this.loading = true;
      try {
        const params = { data: this.form.roleId };
        const res = await getUserPowerByRoleId(params);
        const listArr = JSON.parse(JSON.stringify(res.roleNodeTree));
        this.dataSource = listToTree(listArr);
        this.loading = false;
        const selectMenus = [];
        listArr.forEach(listItem => {
          if (listItem.isSelect === 1 && listItem.children.length === 0) {

            selectMenus.push(listItem)
          }
        })
        this.$nextTick(() => {
          this.$refs[this.tableRef].setCheckboxRow(selectMenus, true)
          this.roleNodes = unionBy([...this.$refs[this.tableRef].getCheckboxIndeterminateRecords(true), ...this.$refs[this.tableRef].getCheckboxRecords(true)])
        });
      } catch (err) {
        this.loading = false;
      }
    },

    handlePowerChange (checked, row, rowIndex, value, optIndex) {
      const { nodeId } = row;
      const index = this.roleNodes.findIndex(item => item.nodeId === nodeId);
      console.log(index, '这里是对应roleNodes中的索引')
      if (checked) {
        if (index !== -1) { // 如果存在，则合并
          this.roleNodes[index].dataPermission = unionBy([...this.roleNodes[index].dataPermission, value]);
        } else { // 如果不存在，则添加
          this.roleNodes[index].dataPermission = unionBy([...this.roleNodes[index].dataPermission, value])
        }
        this.dataSource[rowIndex].dataPermission = unionBy([...this.dataSource[rowIndex].dataPermission, value]);
      } else {
        if (index !== -1) { // 如果在roleNodes 存在
          this.roleNodes[index].dataPermission.splice(optIndex, 1)
        }
        this.dataSource[rowIndex].dataPermission.splice(optIndex, 1)
      }
    },
    handleCheckboxChange () {
      this.roleNodes = unionBy([...this.$refs[this.tableRef].getCheckboxIndeterminateRecords(true), ...this.$refs[this.tableRef].getCheckboxRecords(true)])
    },
    handleCheckboxAll ({ records, checked, row }) {

      if (checked) {
        this.roleNodes = records;
      } else {
        this.roleNodes = []
      }
    }
  }
};
</script>

<style scoped>
.scroll-content {
  height: 400px;
  overflow-y: auto;
}
</style>
