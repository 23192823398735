<template>
  <section class="position-wrapper">
    <search @search="handleSearch" @reset="handleRest" />
    <div class="table-wrapper" v-loading="loading">
      <vxe-grid
        border
        :rowId="rowId"
        :ref="tableRef"
        :data="dataSource"
        :columns="tableColumn"
        size="small"
        v-bind="tableOptions"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
      >
        <template #empty>
          <el-empty />
        </template>
        <template #isDisable="{ row }">
          <span>{{ row.isDisable === 0 ? '启用' : '停用' }}</span>
        </template>
        <template #toolbar_buttons>
          <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
          <el-button size="small" @click="handleShowMultipleIsDisableModal(0)">启用</el-button>
          <el-button size="small" @click="handleShowMultipleIsDisableModal(1)">停用</el-button>
        </template>
        <template #updateTime="{ row }">
          <span>{{ dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <template #operate="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleShowIsDisableModal(row)">
            {{ row.isDisable === 0 ? '停用' : '启用' }}
          </el-button>
          <el-button type="text" @click="handleShowRoleMenuModal(row)">角色权限</el-button>
          <el-button type="text" @click="handleShowDeleteModal(row)">删除</el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
      <edit-role ref="modalForm" @ok="modalFormOk" />
      <menu-tree-modal ref="MenuTreeModal" @setPermissionOK="setPermissionOK" />
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import Search from './components/search/index.vue';
import IPagination from '@/components/i-pagination/index.vue';
import EditRole from './components/EditRole.vue';
import MenuTreeModal from './components/MenuTreeModal.vue';
import TableListMixin from '@/minixs/TableListMinix';

export default {
  name: 'List',
  mixins: [TableListMixin],
  components: {
    Search,
    IPagination,
    EditRole,
    MenuTreeModal
  },
  data() {
    return {
      loading: false,
      tableRef: 'RoleTable',
      modalForm: 'modalForm',
      isEdit: false,
      isShowMenuModal: false,
      searchForm: {},
      rowId: 'roleId',
      tableColumn: [
        { type: 'checkbox', width: 50, fixed: 'left' },
        { field: 'roleId', title: '角色ID', fixed: 'left', width: 100 },
        { field: 'roleName', title: '角色名称' },
        { field: 'description', title: '角色描述' },
        {
          field: 'updateTime',
          title: '最后修改时间',
          width: 180,
          slots: { default: 'updateTime' }
        },
        {
          field: 'isDisable',
          title: '启用状态',
          width: 100,
          slots: {
            default: 'isDisable'
          }
        },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      url: {
        list: 'user-web-api/admin/role/getRolePage',
        singleDeleteUrl: 'user-web-api/admin/role/delRole',
        status: 'user-web-api/admin/role/updateBatchIsDisable'
      },
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        },
        checkboxConfig: {
          // checkField:'rankId',
          checkRowKeys: [],
          reserve: true // 保留勾选状态
        }
      }
    };
  },
  mounted() {},
  methods: {
    dayjs,

    handleEditOk() {
      this.loadData();
    },
    // 单个启用禁用
    handleShowIsDisableModal(row) {
      const that = this;
      const upadateFlag = row.isDisable === 0 ? 1 : 0;
      const params = {
        data: { roleIds: [row.roleId], upadateFlag }
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 多个启用禁用
    handleShowMultipleIsDisableModal(isDisable) {
      console.log(this.checkedRows, 'this.checkedRowsthis.checkedRowsthis.checkedRows');
      const that = this;
      const roleIds = this.checkedRows.map((item) => item.roleId);
      if (roleIds.length === 0) {
        this.$message.closeAll();
        this.$message({
          message: '至少选择一条数据',
          type: 'warning'
        });
        return;
      }
      const params = {
        data: { roleIds, upadateFlag: isDisable }
      };
      const message = isDisable === 0 ? '是否确定启用？' : '是否确定停用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params, 2);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },

    handleShowDeleteModal(row) {
      const that = this;
      const params = {
        data: { roleId: row.roleId }
      };
      const message = '确定要删除吗？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleDelete(params);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },

    handleShowRoleMenuModal(row) {
      this.$refs.MenuTreeModal.visible = true;
      this.$refs.MenuTreeModal.form.roleId = row.roleId;
      this.$refs.MenuTreeModal.form.roleName = row.roleName;
      this.$refs.MenuTreeModal.form.description = row.description;

      // this.$router.push({ name: 'RoleMenuList', query: { roleId: row.roleId } });
    },

    setPermissionOK() {
      this.loadData();
    }
  }
};
</script>

<style scoped lang="less">
.position-wrapper {
  .table-wrapper {
    background: #fff;
    padding: 0 20px;
  }
}
</style>
